import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_ioBroker_Tutorials/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
export const _frontmatter = {
  "title": "ioBroker MQTT Adapter cannot connect",
  "path": "/Frequently_Asked_Question/ioBroker_MQTT_Broker_vs_Client/",
  "dateChanged": "2020-03-23",
  "author": "Mike Polinowski",
  "excerpt": "I installed my MQTT camera according to your guide in ioBroker. But the adapter connection status does not switch from yellow to green. I checked the log file and it seems that the adapter cannot connect to the MQTT broker on my camera:",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras",
  "category": "smarthome",
  "type": "ioBroker"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='FAQs - ioBroker MQTT Adapter cannot connect' dateChanged='2020-03-19' author='Mike Polinowski' tag='INSTAR IP Camera' description='I installed my MQTT camera according to your guide in ioBroker. But the adapter connection status does not switch from yellow to green. I checked the log file and it seems that the adapter cannot connect to the MQTT broker on my camera:' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/ioBroker_MQTT_Broker_vs_Client/' locationFR='/fr/Frequently_Asked_Question/ioBroker_MQTT_Broker_vs_Client/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "iobroker-mqtt-adapter-cannot-connect",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#iobroker-mqtt-adapter-cannot-connect",
        "aria-label": "iobroker mqtt adapter cannot connect permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ioBroker MQTT Adapter cannot connect`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I installed my `}<Link to="/Advanced_User/INSTAR_MQTT_Broker/" mdxType="Link">{`MQTT`}</Link>{` camera `}<Link to="/Advanced_User/INSTAR_MQTT_Broker/ioBroker/" mdxType="Link">{`according to your guide in ioBroker`}</Link>{`. But the adapter connection status does not switch from `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`yellow`}</code>{` to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`green`}</code>{`. I checked the log file and it seems that the adapter cannot connect to the MQTT broker on my camera:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`Try to connect to mqtt://admin:*******************@192.168.2.111:1883?clientId`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`iobroker
info	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3148`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` starting. Version `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2.1`}</span>{`.3 `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` /opt/iobroker/node_modules/iobroker.mqtt, node: v10.21.0, js-controller: `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3.1`}</span>{`.5
info	instance system.adapter.mqtt.1 started with pid `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3148`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><strong parentName="p">{`A`}</strong>{`: Our `}<Link to="/Advanced_User/INSTAR_MQTT_Broker/ioBroker/#mqtt-adapter" mdxType="Link">{`ioBroker Guide`}</Link>{` uses the MQTT service of your INSTAR Full HD camera as your MQTT broker. Accordingly, you have to set the MQTT Adapter to `}<strong parentName="p">{`Client/subscriber`}</strong>{` mode and connect it to your camera. But we found that the adapter, configured as an MQTT client, does have issues with special characters inside the MQTT login. The INSTAR MQTT Broker supports the following special characters `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`!#()*-./<?@[]^_{|}`}</code>{`. We tried using this collection of characters as our MQTT login with the MQTT Adapter in client configuration and the connection could not be established (connection status stayed `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`yellow`}</code>{`):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/501e8113b468b2f1169c49b90a39f078/00d43/INSTAR_MQTT_with_ioBroker_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "7.391304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAARUlEQVQI1x3BMQqAMAwFUO9/MbFSty5CIIUfMQmNHSwILoLvTUfdmdXcxzUWS7Ol535PdwGICECU0tbc8hZco/f4iYiqfl06NZEUXKz/AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/501e8113b468b2f1169c49b90a39f078/e4706/INSTAR_MQTT_with_ioBroker_01.avif 230w", "/en/static/501e8113b468b2f1169c49b90a39f078/d1af7/INSTAR_MQTT_with_ioBroker_01.avif 460w", "/en/static/501e8113b468b2f1169c49b90a39f078/7f308/INSTAR_MQTT_with_ioBroker_01.avif 920w", "/en/static/501e8113b468b2f1169c49b90a39f078/445fc/INSTAR_MQTT_with_ioBroker_01.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/501e8113b468b2f1169c49b90a39f078/a0b58/INSTAR_MQTT_with_ioBroker_01.webp 230w", "/en/static/501e8113b468b2f1169c49b90a39f078/bc10c/INSTAR_MQTT_with_ioBroker_01.webp 460w", "/en/static/501e8113b468b2f1169c49b90a39f078/966d8/INSTAR_MQTT_with_ioBroker_01.webp 920w", "/en/static/501e8113b468b2f1169c49b90a39f078/a5d4d/INSTAR_MQTT_with_ioBroker_01.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/501e8113b468b2f1169c49b90a39f078/81c8e/INSTAR_MQTT_with_ioBroker_01.png 230w", "/en/static/501e8113b468b2f1169c49b90a39f078/08a84/INSTAR_MQTT_with_ioBroker_01.png 460w", "/en/static/501e8113b468b2f1169c49b90a39f078/c0255/INSTAR_MQTT_with_ioBroker_01.png 920w", "/en/static/501e8113b468b2f1169c49b90a39f078/00d43/INSTAR_MQTT_with_ioBroker_01.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/501e8113b468b2f1169c49b90a39f078/c0255/INSTAR_MQTT_with_ioBroker_01.png",
              "alt": "INSTAR MQTT for ioBroker",
              "title": "INSTAR MQTT for ioBroker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`But if you `}<Link to="/Frequently_Asked_Question/ioBroker_as_MQTT_Broker_for_your_Camera/" mdxType="Link">{`configure the adapter as your MQTT Broker`}</Link>{` you will be able to use all those characters listed above (see screenshot above - the broker instance of the MQTT Adapter is running using the same login).`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      